@mixin box-shadow-resume(){
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

@mixin fond-blanc() {
    background: #FFFFFF;
    //box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    @include box-shadow-resume();
}


@mixin social-menu($icon-color, $bg_hover_color){
    ul{
        text-align: center;
        margin-bottom: 0;
        >li{
            padding: 0px;
            display: inline-block;
            margin: 5px 5px;
            transition: margin 0.3s ease;
            a{
                line-height: 40px;
                width: 40px;
                height: 40px;
                position: relative;
                display: inline-block;
                border-radius: 50%;
                background-color: transparent;
                transition: background-color 0.3s ease;
                .icon{
                    font-size: 25px;
                    line-height: 45px;
                    color: $icon-color;
                    display: block;
                    vertical-align: middle;
                }
                &:hover{
                    text-decoration: none;
                    background-color: $bg_hover_color;
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        ul{
            >li{
                margin: 5px 20px;
            }
        }
    }
}