section#work-experiences{
    margin-bottom: 0px;
    .wrapper-list{
        position: relative;
        &:after{
            content: '';
            display: block;
            width: 4px;
            min-height: 100%;
            height: 100%;
            position: absolute;
            background: rgba($main-color-resume, 0.16);
            z-index: 1;
            top: 0px;
            left: 50%;
            margin-left: -2px;
        }
    }
    ul{
        li{
            z-index: 10;
            position: relative;
            display: block;
            margin-bottom: 30px;
            .timeline-background{
                @include fond-blanc();
                border-top: 3px solid $main-color-resume;
                padding: 15px 30px;
            }
            p.date{
                text-align: center;
                text-transform: uppercase;
                color: $main-color-resume;
                margin: 0;
                font-weight: 600;
            }
            div.logo{
                margin: 15px 0px;
                a{
                    img{
                        filter: grayscale(100%);
                        transition: filter 0.3s ease;
                    }
                    &:hover{
                        img{
                            filter: grayscale(0%);
                        }
                    }
                }

            }
            div.job{
                text-align: center;
                color: $text-muted;
            }
            div.job-description{
                margin: 20px 0px;
            }
            div.learning{
                font-size: 12px;
                span.text-uppercase{
                    font-weight: 600;
                }
            }
        }
    }
}

/**
* Small Screen - Breack point
*/
@media (min-width: $screen-sm-min) {
    section#work-experiences{
        ul{
            @include clearfix();
            li{
                width: 50%;
                margin-bottom: 0px;
                margin-top: 30px;
                position: relative;
                .timeline-wrapper{
                    .timeline-background{
                        &:before{
                            content: '';
                            display: block;
                            position: absolute;
                            right: 13px;
                            top: 40px;
                            width: 17px;
                            height: 50px;
                            background: url("../img/arrow-resume.png") no-repeat -17px 0px;
                        }
                    }
                }
                &:before{
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: $main-color-resume;
                    position: absolute;
                    border-radius: 50%;
                    top: 62px;
                    left: -4px;
                }
            }
            li:nth-child(odd) {
                //background-color: red;
                float: left;
                clear: left;
                margin-top: 30px;
                .timeline-wrapper{
                    padding-right: 30px;
                }
                &:before{
                    right: -4px;
                    left: auto;
                }
            }
            li:nth-child(even) {
                //background-color: green;
                float: right;
                clear: right;
                .timeline-wrapper{
                    padding-left: 30px;
                    .timeline-background{
                        &:before{
                            background-position: 0px 0px;
                            right: auto;
                            left: 13px;

                        }
                    }
                }
            }
            li:nth-child(1) {
                &:before{
                    top: 141px;
                }
                .timeline-wrapper{
                    .timeline-background{
                        &:before{
                            top: 120px;
                        }
                    }
                }
            }
            li:nth-child(2), li:nth-child(6) {
                margin-top: 70px;
            }
            li:last-child{
                padding-bottom: 40px;
            }
        }
    }
}