@charset "UTF-8";

@font-face {
    font-family: "resume-achappard";
    src:url("fonts/resume-achappard/resume-achappard.eot");
    src:url("fonts/resume-achappard/resume-achappard.eot?#iefix") format("embedded-opentype"),
    url("fonts/resume-achappard/resume-achappard.woff") format("woff"),
    url("fonts/resume-achappard/resume-achappard.ttf") format("truetype"),
    url("fonts/resume-achappard/resume-achappard.svg#resume-achappard") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "resume-achappard" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "resume-achappard" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
    content: "\74";
}
.icon-calendar:before {
    content: "\63";
}
.icon-deefuse:before {
    content: "\64";
}
.icon-facebook:before {
    content: "\66";
}
.icon-github:before {
    content: "\67";
}
.icon-linkedin:before {
    content: "\6c";
}

.icon-wordpress:before {
  content: "\61";
}
