section#card.resume-sectiom{
    margin-top: 0px;
}
section#card{
    @include fond-blanc();
    #photo{
        margin-bottom: 30px;
    }
    #personal-infos{
        padding: 30px 20px;
        text-align: center;
        p{
            display: inline-block;
            background: $main-color-resume;
            color: #FFF;
            padding: 9px 12px;
            min-width: 150px;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1.1;
            font-weight: 700;
            position: relative;
            text-align: center;
            margin: 0px auto;
            &:before{
                content: '';
                width: 0;
                height: 0;
                top: 100%;
                left: 5px;
                display: block;
                position: absolute;
                border-style: solid;
                border-width: 0 0 8px 8px;
                border-color: transparent;
                border-left-color: $main-color-resume;
            }

        }
        h1{

            font-weight: 700;
            font-size: 25px;
            span{
                font-weight: 300;
                font-size: 22px;
            }
        }
        h2{
            font-size: 15px;
            font-weight: 400;
            margin-top: 0px;
            line-height: 1.1;
        }
        hr{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        dl{
            text-align: left;
            margin-bottom: 0px;
            dt{
                text-transform: uppercase;
                font-size: 12px;
                .label{
                    font-size: 12px;
                    margin-bottom: 10px;
                    text-transform: none;
                    line-height: 2;
                }
            }
            dd{
                font-size: 15px;
                margin-bottom: 27px;
                line-height: 1.2;
                .icon{
                    vertical-align: middle;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
    .social-menu{
        background: $main-color-resume;
        padding: 15px 0px;
        @include social-menu(#FFFFFF, rgba(#000000, 0.1));
    }
}

/**
* Small Screen - Breack point
*/
@media (min-width: $screen-sm-min) {
    section#card{
        #wrapper-info{
            padding-left: 30px;
        }
        #personal-infos{
            padding: 57px 50px 15px;
            text-align: left;
            p{
                min-width: auto;
                padding: 7px 12px;
            }

            dl{

                dt{
                    line-height: 22px;
                    text-align: left;
                    width: 120px;
                    .label{
                        //line-height: 1;
                    }
                }
                dd{
                    margin-left: 120px;
                    margin-bottom: 16px;
                    &.with-label{
                        padding-top: 2px;
                    }
                }
            }
        }
    }
}